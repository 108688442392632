import { Powerup } from "./Powerup";

export class Coin {
    public powerups: Array<Powerup>;
    public front = require("../../assets/chars/placeholder.png");
    public back = require("../../assets/frames/backside.png");
    public frame = require("../../assets/frames/frame.png");

    public setPowerups(powerups) {
        this.powerups = powerups;
    }
}
