import { Powerup } from "../structs/Powerup";

export class CoinGenerator {
    public static getPowerupURLs(powerups: Array<Powerup>): Array<string> {
        if (powerups === undefined) return [];
        return powerups.map((powerup: Powerup) => {
            return powerup.src;
        });
    }

    public static preloadImagesFromUrls(imageUrls: Array<string>, p5) {
        return imageUrls.map((imgSrc) => {
            return p5.loadImage(imgSrc);
        });
    }

    public static preloadImageFromUrl(imageUrl: string, p5) {
        if (imageUrl === "") return "";
        return p5.loadImage(imageUrl);
    }

    public static readonly powerupLocation = [
        {
            x: 70 - 25,
            y: 310 - 25,
            rotation: Math.PI / 5,
        },
        {
            x: 35 - 25,
            y: 164 - 25,
            rotation: Math.PI / 5,
        },
        {
            x: 180 - 25,
            y: 350 - 25,
            rotation: Math.PI / 5,
        },
        {
            x: 337 - 25,
            y: 120 - 25,
            rotation: Math.PI / 5,
        },
    ];
}
