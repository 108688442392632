import { Powerup } from "../structs/Powerup";

// list of items go here
export class Powerups {
    static imgSources: Array<Powerup> = [
        {
            src: require("../../assets/powerups/camera.png"),
            selected: false,
            alt: "camera",
            properties: {
                Type: "Camera",
                Ability: "Take a photo",
                "Total count": 1,
            },
        },
        {
            src: require("../../assets/powerups/lightning.png"),
            selected: false,
            alt: "lightning",
            properties: {
                Type: "Lightning",
                Ability: "Strike down from the sky",
                "Total count": 2,
            },
        },
        {
            src: require("../../assets/powerups/heart.png"),
            selected: false,
            alt: "heart",
            properties: {
                Type: "Heart",
                Ability: "Fill with love",
                "Total count": 3,
            },
        },
        {
            src: require("../../assets/powerups/palm.png"),
            selected: false,
            alt: "palm",
            properties: {
                Type: "Palm",
                Ability: "Grow some nuts",
                "Total count": 4,
            },
        },
        {
            src: require("../../assets/powerups/palm.png"),
            selected: false,
            alt: "palm",
            properties: {
                Type: "Palm",
                Ability: "Grow some nuts",
                "Total count": 4,
            },
        },
    ];
}
