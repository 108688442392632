export class Powerup {
    public src: string;
    public selected: boolean;
    public alt: string;
    public properties = {
        Type: "string",
        Ability: "string",
        "Total count": 1,
    };
}
